<template>
  <vs-row>
    <vs-col vs-w="12">
      <table
        style="text-align: centerborder: 1px solid #e2dfdf;border-collapse: collapse;border-radius: 5px;border-style: hidden;box-shadow: 0 0 0 1px #e2dfdf;"
      >
        <tr>
          <th style="border: 1px solid #e2dfdf; border-collapse: collapse">
            No. Of days worked in the current month
          </th>
          <th style="border: 1px solid #e2dfdf; border-collapse: collapse">
            Leaves Allowed
          </th>
          <!-- <th style="border: 1px solid #e2dfdf; border-collapse: collapse">
            Total Leaves Taken - Jan - Nov
          </th> -->
          <th style="border: 1px solid #e2dfdf; border-collapse: collapse">
            Current Month Leaves
          </th>
          <!-- <th
            style="border: 1px solid #e2dfdf;
  border-collapse: collapse;"
          >
            Total Leaves Taken
          </th>
          <th style="border: 1px solid #e2dfdf; border-collapse: collapse">
            Leftover Leaves
          </th> -->
          <th style="border: 1px solid #e2dfdf; border-collapse: collapse">
            Current Month Comp Off
          </th>
          <th style="border: 1px solid #e2dfdf; border-collapse: collapse">
            Total Comp Off
          </th>
          <th style="border: 1px solid #e2dfdf; border-collapse: collapse">
            POTENTIAL LOP
          </th>
          <th style="border: 1px solid #e2dfdf; border-collapse: collapse">
            Action
          </th>
        </tr>
        <tr>
          <td
            style="
              border-left: 1px solid #e2dfdf;
              border: 1px solid #e2dfdf;
              border-collapse: collapse;
            "
          >
            {{ this.attendanceSummary.working_days }}
          </td>
          <td style="border: 1px solid #e2dfdf; border-collapse: collapse">
            {{ this.attendanceSummary.leaves_allowed }}
          </td>
          <!-- <td style="border: 1px solid #e2dfdf; border-collapse: collapse">
            {{ this.attendanceSummary.total_leaves_taken_till_last_month }}
          </td> -->
          <td style="border: 1px solid #e2dfdf; border-collapse: collapse">
            {{ this.attendanceSummary.current_month_leaves }}
          </td>
          <!-- <td
            style="border: 1px solid #e2dfdf;
  border-collapse: collapse;"
          >
            {{ this.attendanceSummary.toatal_taken_leaves_nov }}
          </td>
          <td style="border: 1px solid #e2dfdf; border-collapse: collapse">
            {{ this.attendanceSummary.left_over_leaves_nov }}
          </td> -->
          <td style="border: 1px solid #e2dfdf; border-collapse: collapse">
            {{ this.attendanceSummary.current_month_comp_offs }}
          </td>
          <td style="border: 1px solid #e2dfdf; border-collapse: collapse">
            {{ this.attendanceSummary.total_comp_off }}
          </td>
          <td style="border: 1px solid #e2dfdf; border-collapse: collapse">
            {{ this.negative(this.attendanceSummary.potential_lop) }}
          </td>
          <td style="border: 1px solid #e2dfdf; border-collapse: collapse">
            <div @click="Showattend(attendanceSummary)">
              <vs-icon icon="visibility" size="small" color="dark"></vs-icon>
            </div>
          </td>
        </tr>
      </table>
    </vs-col>
  </vs-row>
</template>

<script>
import EventBus from "../eventbus.js";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import axios from "axios";
import constants from "../../../constants.json";
export default {
  props: {
    attendance_summary: {
      required: true,
      type: Object,
    },
    periodStart: {
      required: true,
      type: String,
    },
  },
  components: {
    moment,
  },
  data() {
    return {
      attendanceSummary: "",
      data: "",
      userid: "",
      month_names: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      month: "",
    };
  },
  mounted() {
    // var month_day = new Date().getDate();

    this.myprofile();
    this.month = moment().format("MMMM");
    console.log("month", this.periodStart);
    console.log("monthEnd ", this.periodEnd);
  },
  methods: {
    myprofile() {
      let url = `${constants.SERVER_API}getUserProfile`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("My profile", response.data.data);
          let data = response.data.data;
          let userid = data.id;
          console.log(userid);
          this.showattendance(userid);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    showattendance(userid) {
      let obj = {
        user_id: userid,
        month_start_date: this.periodStart,
      };
      let url = `${constants.SERVER_API}getLeaveRecords`;
      axios
        .get(url, {
          params: obj,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("attendance data", response.data);
          // EventBus.$emit("open-Showattendance", this.userid);
          this.attendanceSummary = response.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    Showattend(attendanceSummary) {
      console.log("show attenedence", attendanceSummary);
      let userId = attendanceSummary.id;
      console.log("userd id", userId);
      EventBus.$emit("open-Showattendance", userId);
    },
    negative(number) {
      // return !Object.is(Math.abs(number), +number);
      console.log(number)
      if (number <= 0) {
            return number;
        } else {
            return '-';
        }
    }
  },
};
</script>

<style>
/* th,
td {
  border: 1px solid #e2dfdf;
  border-collapse: collapse;
  /* border-radius: 5px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #e2dfdf; */
</style>
