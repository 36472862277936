<template>
  <vs-row>
    <!-- This Month -->
    <vs-col vs-w="6">
      <vs-card>
        <vs-row>
          <vs-col vs-align="center" vs-w="2">
            <p style="fornt-size:8px">This month:</p>
          </vs-col>
          <vs-col vs-w="10">
            <table style="text-align: center">
              <tr>
                <th>Working days</th>
                <th>Leaves</th>
                <th>Comp Offs</th>
                <th>Potential LOP</th>
              </tr>
              <tr >
                <td>{{attendance_summary.this_month.working_days}}</td>
                <td>{{attendance_summary.this_month.leaves}}</td>
                <td>{{attendance_summary.this_month.comp_offs}}</td>
                <td>{{attendance_summary.this_month.potential_lop}}</td>
              </tr>
            </table>
          </vs-col>
        </vs-row>
      </vs-card>
    </vs-col>

    <!-- Last Month -->
    <vs-col vs-w="6">
      <vs-card>
        <vs-row>
          <vs-col vs-align="center" vs-w="2">
            <p style="fornt-size:8px">upto last month:</p>
          </vs-col>
          <vs-col vs-w="10">
            <table style="text-align: center">
              <tr>
                <th>Allowed</th>
                <th>taken</th>
                <th>Remaining</th>
                <th>Comp Offs</th>
                <th>LOP</th>
                <th></th>
                <th></th>
              </tr>
              <tr >
                <td>{{attendance_summary.master_attendance.allowed}}</td>
                <td>{{attendance_summary.master_attendance.taken}}</td>
                <td>{{attendance_summary.master_attendance.remaining}}</td>
                <td>{{attendance_summary.master_attendance.comp_offs}}</td>
                <td>{{attendance_summary.master_attendance.lop}}</td>
                <td></td>
                <td><div @click="Showattend(attendance_summary)">
                  <vs-icon
                    icon="visibility"
                    size="small"
                    color="dark"
                  ></vs-icon>
                </div></td>
              </tr>
            </table>
          </vs-col>
        </vs-row>
        
        
      </vs-card>
    </vs-col>
  </vs-row>
</template>

<script>
import EventBus from "../eventbus.js";
export default {
  props: {
    attendance_summary:{
      required: true,
      type: Object
    }
  },
  methods: {
    Showattend(attendance_summary) {
      console.log("show attenedence", attendance_summary)
      let userId = attendance_summary.this_month.user_id;
      EventBus.$emit("open-Showattendance", userId);
    }
  }
}
</script>

<style>

</style>