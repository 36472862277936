<template>
<!-- <vx-card> -->
    <vs-row>
        <vs-col vs-w="2">
            <a target="_blank" href="https://forms.gle/nRREGMncBJ97Caa87"><vs-button color="primary" type="border" style="font-size: 11px;">Employee Personal Files</vs-button></a>
        </vs-col>
        <vs-col vs-w="2">
            <a target="_blank" href="https://forms.gle/LQwEJnAxnChTLz9u6"><vs-button color="success" type="border" style="font-size: 11px;">Employee Database form</vs-button></a>
        </vs-col>
        <vs-col vs-w="2.3">
            <a target="_blank" href="https://forms.gle/okQpNa7HUdVpxTPY7"><vs-button color="danger" type="border" style="font-size: 11px;">Assets Allocation & Verification</vs-button></a>
        </vs-col>
        <vs-col vs-w="2.5">
            <a target="_blank" href="https://forms.gle/fwyUL6qb6NeA4ccf9"><vs-button color="warning" type="border" style="font-size: 11px;">Asset Replacement or Additional Allocation</vs-button></a>
        </vs-col>
        <vs-col vs-w="3">
            <a target="_blank" href="https://forms.gle/iCZ7VhF3ErGpMPZ69"><vs-button color="dark" type="border" style="font-size: 11px;">Assets Requirement/Complaint Register</vs-button></a>
        </vs-col>
  </vs-row>
<!-- </vx-card> -->
  
</template>

<script>
// import EventBus from "../eventbus.js";
// import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
// import moment from "moment";
// import axios from "axios";
// import constants from "../../../constants.json";
export default {
  components: {
    // moment,
  },
  data() {
    return {
      
    };
  },
  mounted() {
    
  },
  methods: {},
};
</script>

<style>
/* th,
td {
  border: 1px solid #e2dfdf;
  border-collapse: collapse;
  /* border-radius: 5px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #e2dfdf; */
</style>
